
//
// Layout.
//

// Make layout align.
@mixin make-layout-align($screen) {
  // Horizontal align - left.
  [layout-align-#{$screen}="start"],
  [layout-align-#{$screen}="start start"],
  [layout-align-#{$screen}="start center"],
  [layout-align-#{$screen}="start end"] {
    text-align: left;
  }

  // Horizontal align - center.
  [layout-align-#{$screen}="center"],
  [layout-align-#{$screen}="center center"],
  [layout-align-#{$screen}="center start"],
  [layout-align-#{$screen}="center end"] {
    text-align: center;
  }

  // Horizontal align - right.
  [layout-align-#{$screen}="end"],
  [layout-align-#{$screen}="end end"],
  [layout-align-#{$screen}="end center"],
  [layout-align-#{$screen}="end end"] {
    text-align: right;
  }

  // Vertical align - top.
  [layout-align-#{$screen}="start"],
  [layout-align-#{$screen}="start start"],
  [layout-align-#{$screen}="center start"],
  [layout-align-#{$screen}="end center"] {
    &::before {
      vertical-align: top;
    }

    > * {
      vertical-align: top;
    }
  }

  // Vertical align - middle.
  [layout-align-#{$screen}="center"],
  [layout-align-#{$screen}="center center"],
  [layout-align-#{$screen}="start center"],
  [layout-align-#{$screen}="end center"] {
    &::before {
      vertical-align: middle;
    }

    > * {
      vertical-align: middle;
    }
  }

  // Vertical align - bottom.
  [layout-align-#{$screen}="end"],
  [layout-align-#{$screen}="end end"],
  [layout-align-#{$screen}="start end"],
  [layout-align-#{$screen}="end end"] {
    &::before {
      vertical-align: bottom;
    }

    > * {
      vertical-align: bottom;
    }
  }
}

@mixin make-margin-for-direction($screen, $direction) {
  @for $index from 0 through 20 {
    [layout-margin-#{$direction}-#{$screen}="#{$index}"] {
      margin-#{$direction}: $base-unit * $index !important;
    }
  }
}

@mixin make-margin-for-all-direction($screen) {
  @for $index from 0 through 20 {
    [layout-margin-#{$screen}="#{$index}"] {
      margin: $base-unit * $index !important;
    }
  }
}

@mixin make-margin-for-vertical-direction($screen) {
  @for $index from 0 through 20 {
    [layout-margin-vertical-#{$screen}="#{$index}"] {
      margin-top: $base-unit * $index !important;
      margin-bottom: $base-unit * $index !important;
    }
  }
}

@mixin make-margin-for-horizontal-direction($screen) {
  @for $index from 0 through 20 {
    [layout-margin-horizontal-#{$screen}="#{$index}"] {
      margin-left: $base-unit * $index !important;
      margin-right: $base-unit * $index !important;
    }
  }
}

@mixin make-padding-for-direction($screen, $direction) {
  @for $index from 0 through 20 {
    [layout-padding-#{$direction}-#{$screen}="#{$index}"] {
      padding-#{$direction}: $base-unit * $index !important;
    }
  }
}

@mixin make-padding-for-all-direction($screen) {
  @for $index from 0 through 20 {
    [layout-padding-#{$screen}="#{$index}"] {
      padding: $base-unit * $index !important;
    }
  }
}

@mixin make-padding-for-vertical-direction($screen) {
  @for $index from 0 through 20 {
    [layout-padding-vertical-#{$screen}="#{$index}"] {
      padding-top: $base-unit * $index !important;
      padding-bottom: $base-unit * $index !important;
    }
  }
}

@mixin make-padding-for-horizontal-direction($screen) {
  @for $index from 0 through 20 {
    [layout-padding-horizontal-#{$screen}="#{$index}"] {
      padding-left: $base-unit * $index !important;
      padding-right: $base-unit * $index !important;
    }
  }
}

@mixin make-column-padding($screen) {
  @for $index from 0 through 10 {
    [layout-col-padding-#{$screen}="#{$index / 10}"] {
      padding: $col-width * ($index / 10);
    }
  }

  @for $index from 0 through 12 {
    [layout-col-padding-#{$screen}="#{$index}"] {
      padding: $col-width * $index;
    }
  }

  @for $index from 0 through 12 {
    [layout-col-padding-top-#{$screen}="#{$index}"] {
      padding-top: $col-width * $index;
    }
  }

  @for $index from 0 through 12 {
    [layout-col-padding-bottom-#{$screen}="#{$index}"] {
      padding-bottom: $col-width * $index;
    }
  }
}

@mixin make-column-height($screen) {
  @for $index from 0 through 12 {
    [layout-col-height-#{$screen}="#{$index}"] {
      min-height: $col-height * $index;
    }
  }
}

@mixin make-pull-for-direction($screen, $direction) {
  [layout-pull-#{$screen}="#{$direction}"] {
    #{$direction}: 0 !important;
  }
}

@mixin make-layout-fill-horizontal($screen) {
  [layout-fill-#{$screen}="horizontal"] {
    width: 100%;
  }
}

@mixin make-layout-fill-vertical($screen) {
  [layout-fill-#{$screen}="vertical"] {
    min-height: 100vh;
  }
}

@mixin make-layout-position($position) {
  [layout-position="#{$position}"] {
    position: $position !important;
  }
}
