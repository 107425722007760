
//
// Footer.
//

footer {
  margin-top: -$unit-3;

  .author {
    padding-right: $unit-2;
  }

  .copyright {
    padding-left: $unit-2;
  }
}

//
// Media queries.
//

// Extra small screens.
@media (min-width: $screen-xs-min) {
  footer {
    margin-top: -$unit-5;

    .author {
      padding-right: $unit-4;
    }

    .copyright {
      padding-left: $unit-4;
    }
  }
}
