
//
// Icons.
//

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-top: -2px;

  vertical-align: middle;

  fill: currentColor;

  &-size-xs {
    @include square(10px);
  }

  &-size-sm {
    @include square(20px);
  }

  &-size-md {
    @include square(24px);
  }

  &-size-lg {
    @include square(44px);
  }
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

// Fill with `currentColor`.
.icon-box-label,
.icon-close,
.icon-truck {
  * {
    fill: currentColor;
  }
}

// Stroke with `currentColor`.
.icon-arrow,
.icon-play {
  * {
    stroke: currentColor;
  }
}

// Custom icons.
.icon-close-nav {
  @include position(relative, -1px, auto, auto, -1px);
}

.icon-media-gallery {
  width: 36px;
  margin-top: -10px;
}
