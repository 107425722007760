
//
// Pages.
//

body {
  background-color: $brown-dark;
}

// Homepage.
@for $index from 1 through 4 {
  .layer-homepage-#{$index} {
    @include img-retina('../images/species/homepage/#{$index}@1x.png', '../images/species/homepage/#{$index}@2x.png', auto, auto);
  }
}

.bg-mobile-homepage {
  @media (max-width: $screen-xs-max) {
    @include img-retina('../images/species/mobile/homepage/1@1x.png', '../images/species/mobile/homepage/1@2x.png', cover, cover);

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

// Not found.
@for $index from 1 through 4 {
  .layer-not-found-#{$index} {
    @include img-retina('../images/species/404/#{$index}@1x.png', '../images/species/404/#{$index}@2x.png', auto, auto);
  }
}

.bg-mobile-not-found {
  @media (max-width: $screen-xs-max) {
    @include img-retina('../images/species/mobile/404/1@1x.png', '../images/species/mobile/404/1@2x.png', cover, cover);

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.not-found-container {
  @include position(absolute, auto, 0, 5vh, 0);
}

// Pois list map.
.intro-points-of-interest {
  z-index: -1;

  opacity: 0;
  transition: $default-transition;

  @include square(100%);

  &::before {
    @extend .pseudos;
    @include create-overlay-background(#fff, .8);
  }
}

.nav-points-of-interest {
  z-index: $zindex-navbar-fixed + 1;

  width: $nav-points-of-interest-width;
  height: 100%;
  padding: 0;

  transform: translateX(100%);
  transition: $default-transition;

  &-head {
    height: $item-poi-size;
    padding: $list-gutter-width $list-gutter-width 0;

    .logo {
      color: $brand-secondary;

      svg {
        width: $logo-width;
        height: $logo-height;
      }
    }

    .btn {
      margin-top: $base-unit / 2;
    }
  }

  &-body {
    @include position(absolute, $item-poi-size + $unit-2, 0, auto, 0);

    height: 100%;
  }

  &-body-container {
    @include position(absolute, 0, 0, $item-poi-size * 2, 0);

    overflow: auto;
  }

  &-footer {
    @include position(absolute, auto, 0, $unit-10, 0);

    height: $item-poi-size;
  }
}

// Body action.
body {
  &.intro-open .intro-points-of-interest {
    z-index: 1;

    opacity: 1;
  }

  &.nav-open .nav-points-of-interest {
    transform: translateX(0);
  }
}

// Contacts.
.contacts-zoom-in,
.contacts-zoom-out {
  position: fixed;
  right: $unit-4;

  display: none;
}

.contacts-zoom-in {
  bottom: $unit-11;
}

.contacts-zoom-out {
  bottom: $unit-4;
}

// Medias.
.medias {
  margin-top: -1px;
}

// Results.
.list-results {
  margin-top: -45px;
}

//
// Media queries.
//

// Extra small screens.
@media (max-width: $screen-xs-min) {
  .nav-points-of-interest {
    z-index: 1;
  }
}

// Small screens.
@media (min-width: $screen-sm-min) {
  .contacts-zoom-in,
  .contacts-zoom-out {
    display: block;
  }
}
