
//
// Species.
//

// Create species background layers image.
@each $key, $specie in $species-list {
  @for $index from 1 through 3 {
    .layer-#{$key}-#{$index} {
      @include img-retina('../images/species/layers/#{$specie}/#{$index}@1x.png', '../images/species/layers/#{$specie}/#{$index}@2x.png', auto, auto);
    }
  }
}

// Images for mobile 320px.
@each $key, $specie in $species-list {
  .bg-mobile-specie-#{$key} {
    @media (max-width: $screen-xs-max) {
      @include img-retina('../images/species/mobile/#{$specie}/1@1x.png', '../images/species/mobile/#{$specie}/1@2x.png', cover, cover);

      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

// Images for menu.
@each $key, $specie in $species-list {
  .specie-img-#{$specie} {
    @include img-retina('../images/species/list/#{$specie}@1x.png', '../images/species/list/#{$specie}@2x.png', auto, auto);
  }
}

// Create heads.
@each $label, $map in $heads {
  $width: map-get($map, width);
  $height: map-get($map, height);

  .head-#{$label} {
    @include img-retina('../images/species/heads/#{$label}.png', '../images/species/heads/#{$label}@2x.png', $width, $height);

    top: ($height - $media-object-size) / 2;
    left: ($width - $media-object-size) / 2;

    width: $width;
    height: $height;

    @media (max-width: $screen-xs-max) {
      transform: scale(.75);

      transform-origin: $media-object-size / 2;
    }
  }
}

//
// Media Object.
//

.media-object {
  position: relative;

  display: inline-block;
}

.media-object-mascot {
  @include img-retina('../images/species/heads/mascote.png', '../images/species/heads/mascote@2x.png', $media-object-mascot-width, $media-object-mascot-height);

  top: $media-object-size-sm - $media-object-mascot-height;
  left: $media-object-size-sm - $media-object-mascot-width;

  width: $media-object-mascot-width;
  height: $media-object-mascot-height;
}

.specie-container {
  margin-top: 150px;
}

//
// Media queries.
//

// Extra small screens.
@media(max-width: $screen-xs-max) {
  .specie-container {
    margin-top: 120px;

    transform: none;
  }

  .layer {
    transform: none !important;
  }
}
