
//
// Parallax.
//

.parallax,
.parallax-container,
.parallax-layers {
  position: relative;

  width: 100%;
}

.parallax-thumbs {
  &,
  .parallax-container,
  .parallax-layers {
    min-height: $col-height * 4;
  }
}

.parallax-full {
  position: absolute;

  &,
  .parallax-container,
  .parallax-layers {
    min-height: $col-height * 12;
  }
}

.parallax,
.parallax-layers {
  transform-style: preserve-3d;
}

.parallax:hover {
  z-index: 10;
}

.parallax-layer-text,
.parallax-rendered-layer,
.parallax-layer-background,
.parallax-shadow,
.parallax-shine,
.parallax-layer-audio {
  @include position(absolute);
}

.parallax-layer-text,
.parallax-rendered-layer,
.parallax-layer-background {
  transition: all .1s ease-out;
}

.parallax-container,
.parallax-shadow {
  transition: all .2s ease-out;
}

.parallax-rendered-layer {
  background-repeat: no-repeat;
}

.parallax-thumbs .parallax-rendered-layer {
  background-position: center bottom;
}

.parallax-full .parallax-rendered-layer {
  background-size: cover;
  background-position: center;
}

.parallax-layer-background-orange {
  background-color: $orange;
}

.parallax-layer-background-dark-orange {
  background-color: $orange-dark;
}

.parallax-layer-background-darken-orange {
  background-color: $orange-darken;
}

.parallax-layer-title {
  @include position(absolute, 50%, auto, auto, -50%);

  width: 100%;
  margin-top: -15px;
  margin-left: 60px;

  transform: rotate(270deg) translateY(-50%) scale(.5);
  transform-origin: center;
  transition: transform .3s ease;
}

.parallax-layer-button {
  @include position(absolute, 50%, 0, auto, 0);

  width: 100%;

  transform: translateY(-50%);

  > span {
    margin-right: 25px;
  }
}

.parallax-layer-audio {
  visibility: hidden;
}

.parallax-container.over {
  .parallax-shadow {
    box-shadow: 0 10px 30px rgba(#000, .25);
  }

  .parallax-shine {
    z-index: 1;
  }

  .parallax-layer-title {
    // text-shadow: 0 1px 8px rgba(#000, .45);

    transform: rotate(270deg) translateY(-50%) scale(.75);
  }
}

// Mousemove parallax.
.parallax-mousemove {
  margin: 0;
  padding: 0;

  &,
  .layer {
    @include position(absolute);
    @include square(100%);

    > span {
      @include position(absolute, -$unit-5, -$unit-5, -$unit-5, -$unit-5);

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
}

.sticky-trigger {
  @include position(absolute, 50vh, auto, auto, auto);

  height: 1px;
}

//
// Media queries.
//

// Small screens.
@media (max-width: $screen-sm) {
  .sticky-xs {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;

    width: 100% !important;
  }
}

// viewport height less than 768px.
@media (max-height: $screen-sm-min) {
  .parallax-thumbs {
    &,
    .parallax-container,
    .parallax-layers {
      min-height: 275px;
    }
  }
}
