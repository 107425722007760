
//
// Image mixins.
//

// Retina cover.
@mixin img-retina-cover($file, $format) {
  @include bg-retina($file, $format, cover, cover);
}

@mixin bg-retina($file, $format, $width: cover, $height: cover) {
  @include img-retina($file + "@1x.#{$format}", $file + "@2x.#{$format}", $width, $height);

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
