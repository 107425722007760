
//
// Scaffolding.
//

// Create background colors.
@mixin create-background-color($name: null, $color: null) {
  .bg-#{$name} {
    background-color: $color;
  }
}

// Create overlay.
@mixin create-overlay-background($bg-color, $opacity, $z-index: 1) {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index;

  background-color: $bg-color;

  opacity: $opacity;
}
