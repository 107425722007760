
//
// Menu.
//

.menu-section {
  &::before {
    @include position(absolute, 0, auto, auto, 0, 10);
    @include square(100%);

    background-color: $brown-dark;
    content: '';

    opacity: 0;
    transform: translateY(-100%);
    transition: opacity $default-transition;
    transition-delay: $fast-transition-duration;
  }
}

// Menu wrapper - `body` - that defines `main-menu` state.
.menu-open {
  overflow: hidden;

  .menu-section::before {
    opacity: .9;
    transform: translateY(0);
  }

  .main-menu {
    transform: translateX(0);
  }
}

.main-menu {
  @include position(fixed, 0, 0, auto, 0, $zindex-navbar-fixed - 1);
  @include square(100%);

  background-color: $brown-dark;

  transform: translateX(-100%);
  transition: $default-transition;

  &:before {
    @include position(fixed, 0, $unit-3, auto, 0, 1);

    display: block;
    height: 100px;

    background: linear-gradient(to bottom, rgba($brown-dark, 1) 50%, rgba($brown-dark, 0) 100%);
    content: '';
  }

  ul {
    display: block;

    text-align: center;

    a {
      color: $brand-primary;

      transition: $default-transition;

      &.active,
      &:hover {
        color: $brand-secondary;
        text-decoration: none;
      }

      &.active {
        pointer-events: none;
        cursor: default;
      }
    }

    &.menu-large > li {
      margin: 0 0 $unit-3;

      a {
        font-size: $font-size-h4;
        line-height: 34px;
        letter-spacing: -.3px;
      }
    }

    &.menu-small > li {
      margin: 0 0 $base-unit;

      a {
        font-size: $base-font-size;
        line-height: 20px;
        letter-spacing: -.18px;
      }
    }

    &.menu-large > li,
    &.menu-small > li {
      &:last-child {
        margin-bottom: 0;
      }

      a {
        font-weight: 100;
      }
    }

    &.menu-languages > li {
      display: inline-block;
      margin: 0 ($base-unit/2);
      a {
        font-size: $font-size-h5;
        font-weight: 500;
      }
    }
  }
}

.main-menu-container {
  @include position(fixed);

  overflow: auto;
}

// Side menu from media.
.menu-media {
  padding: 0;
  margin: 1px 0 0;

  &-item {
    display: flex;
    height: $menu-media-height;

    text-align: center;

    align-items: center;
    justify-content: center;

    &-link {
      position: relative;

      display: block;

      color: $brown;

      &::after,
      &::before {
        bottom: 0;
        left: 0;

        width: 100%;
        height: 2px;

        transform: scale3d(0, 1, 1);
        transition: transform $media-transition;
        transform-origin: center left;

        @extend .pseudos;
      }

      &::before {
        background-color: $brown;

        transition-delay: $media-transition-duration;
      }

      &::after {
        background-color: $orange;
      }

      &:hover {
        color: $brown;
        text-decoration: none;

        &::before {
          transform: scale3d(1, 1, 1);
          transition-delay: 0s;
        }
      }

      &.active {
        color: $orange;

        pointer-events: none;
        cursor: default;

        &::after {
          transform: scale3d(1, 1, 1);
        }
      }
    }
  }
}

//
// Media queries.
//

// Extra small screens.
@media (min-width: $screen-xs-min) {
  .main-menu {
    width: $menu-width;
  }
}
