
//
// Positions mixins.
//

@mixin position($position: absolute, $top: 0, $right: 0, $bottom: 0, $left: 0, $z-index: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;

  @if $z-index {
    z-index: $z-index;
  }
}
