
//
// Panels.
//

.panel-default {
  border: 0;
  border-radius: 0;

  box-shadow: none;
}

.panel-body {
  padding: $unit-3;
}

.panel-results {
  @extend .panel-default;

  box-shadow: $box-shadow-small;
}

.panel-heading-results {
  position: relative;

  .icon {
    @include position(absolute, 50%, $unit-3, auto, auto);
    @include square(14px);

    margin-top: 2px;

    transform: translateY(-50%) rotate(270deg);

    transition: transform $fast-transition-duration;
  }

  &.collapsed {
    color: $brown;

    .icon {
      transform: translateY(-50%) rotate(90deg);
    }
  }

  &:hover {
    color: $orange;
  }
}

.panel-body-table-species {
  &-family {
    border-bottom: 2px solid $gray;
  }

  &-morfology {
    border-bottom: 2px solid $gray;
  }
}

.panel-table-contacts {
  border: 2px solid rgba($brand-secondary, .5);
}

.panel-body-table-contacts {
  border-bottom: 2px solid rgba($brand-secondary, .5);

  &:last-child {
    border-bottom: 0;
  }
}

//
// Media queries.
//

// Small screens.
@media(min-width: $screen-md-min) {
  .panel-table-species {
    border: 2px solid $gray;
  }

  .panel-table-species-morfology {
    border-left: 2px solid $gray;
  }
}

// Large screens.
@media(min-width: $screen-lg-min) {
  .panel-body-table-species {
    &-morfology {
      border-bottom: 0;
    }

    &-description {
      border-left: 2px solid $gray;
    }
  }
}
