
//
// Animation.
//

@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}

@keyframes line-height {
  0% {
    stroke-dasharray: 65;
  }
  100% {
    stroke-dasharray: 21;
  }
}
