
//
// Buttons.
//

.no-cursor {
  pointer-events: none;
  cursor: default;
}

a {
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;

    outline: none;
  }
}

// Simple button.
.btn {
  font-size: $base-font-size;

  box-shadow: none;
  transition: $fast-transition;
  transition-property: background-color, color, border;

  @include button-states {
    text-decoration: none;

    outline: none;

    box-shadow: none;
  }

  &-xs {
    height: $button-sm-height;
    min-width: $button-xs-width;
    padding: 9px $unit-3;

    border-radius: $button-sm-radius;
  }

  &-sm {
    height: $button-sm-height;
    min-width: $button-width;
    padding: 9px 12px;

    border-radius: $button-sm-radius;
  }

  &-md {
    height: $button-md-height;
    min-width: $button-width;
    padding: 18px;

    border-radius: $button-md-radius;
  }

  &-full-width {
    width: 100%;
  }

  &-circle-xs,
  &-circle-md,
  &-circle-lg {
    padding: 0;

    border-radius: 50%;
  }

  &-circle-xs {
    line-height: $button-rounded-size-xs - 4px;

    @include square($button-rounded-size-xs);
  }

  &-circle-md {
    line-height: $button-rounded-size-md - 4px;

    @include square($button-rounded-size-md);
  }

  &-circle-lg {
    line-height: $button-rounded-size-lg - 4px;

    @include square($button-rounded-size-lg);
  }

  &-icon-with-text {
    border: 0;
  }

  &-content-icon {
    display: inline-block;

    border: 2px solid;
    border-radius: 50%;
  }

  &-content-text {
    @extend .p;
  }

  &-load-more {
    height: $button-sm-height;
    min-width: auto;
    padding: 10px 30px;

    border-radius: $button-sm-radius;
  }
}

.load-more {
  overflow: hidden;

  transition: all .2s ease, background-color .01s ease, color .01s ease;

  &-loading {
    padding: 0;

    color: transparent !important;

    pointer-events: none;
    cursor: default;
    border: 2px solid $brand-secondary !important;
    border-top-color: $orange !important;

    box-sizing: border-box;
    animation: rotation 1.5s linear infinite;
    animation-delay: .2s;
    transform-origin: center;

    @include square($button-sm-height);
  }
}

.btn-scroll-down {
  bottom: $unit-4;

  pointer-events: none;

  &,
  .icon {
    position: absolute;
    left: 50%;

    transform: translateX(-50%);
  }

  &:hover {
    transition: color $default-transition;
  }

  .icon {
    width: 2px;
    height: 25px;

    top: $unit-5;

    path {
      stroke-dashoffset: 100;

      animation: line-height 2s linear infinite;
    }
  }

  &:hover {
    color: $orange;
  }
}

.btn-pois-back-to-list {
  @include position(fixed, 96px, auto, auto, $unit-4);
}

.btn-contacts-mail {
  bottom: $button-sm-height / -2;
}

.btn-list-markers-control {
  position: absolute;
  right: $unit-4;
  bottom: ($marker-control-height * 2) + $unit-6;

  width: $marker-control-width;
  height: $marker-control-height;
  min-width: $marker-control-width;

  background-position: left bottom;
  background-repeat: no-repeat;
  border: 0;

  @include img-retina('../images/pattern-lines.png', '../images/pattern-lines@2x.png', 75px, 26px);

  .icon {
    width: 25px;
    height: 33px;
    margin-bottom: $base-unit;
  }
}

// Themes.
@include create-button(
  'orange',
  $bg-hover: $orange,
  $bg: transparent,
  $border-hover: $orange,
  $border: rgba($orange, .5),
  $color-hover: #fff,
  $color: $orange
);

@include create-button(
  'bg-orange',
  $bg-hover: $brand-secondary,
  $bg: $orange,
  $border: $orange,
  $color-hover: $orange,
  $color: $brand-secondary
);

@include create-button(
  'bg-brown-darken',
  $bg-hover: rgba($brown-darken, .7),
  $bg: $brown-darken,
  $border: $brown-darken,
  $color-hover: $orange,
  $color: $orange
);

@include create-button(
  'primary',
  $bg-hover: $brand-primary,
  $bg: transparent,
  $border-hover: $brand-primary,
  $border: rgba($brand-primary, .5),
  $color-hover: #fff,
  $color: $brand-primary
);

@include create-button(
  'secondary',
  $bg-hover: $brand-secondary,
  $bg: transparent,
  $border-hover: $brand-secondary,
  $border: rgba($brand-secondary, .5),
  $color-hover: $orange,
  $color: $brand-secondary
);

@include create-button(
  'gray',
  $bg-hover: $gray,
  $bg: transparent,
  $border-hover: $gray,
  $border: rgba($gray, .5),
  $color-hover: $orange,
  $color: $gray
);

@include create-button(
  'white',
  $bg-hover: #fff,
  $bg: transparent,
  $border-hover: #fff,
  $border: rgba(#fff, .5),
  $color-hover: $orange,
  $color: #fff
);

@include create-button-bordered(
  'orange',
  $bg-hover: $orange,
  $bg: transparent,
  $border-hover: $orange,
  $border: $brand-primary,
  $color-hover: $orange,
  $color-icon-hover: #fff,
  $color-icon: $orange,
  $color: $orange
);
