
//
// Videojs.
//

.video-js {
  .vjs-big-play-button {
    @extend .btn;
    @extend .btn-circle-lg;
    @extend .btn-white;
    @extend .layout-absolute-center;

    visibility: hidden;

    &::before {
      background-position: 56% 50%;
      background-repeat: no-repeat;
      content: '';

      @include img-retina('../images/play.png', '../images/play@2x.png', 19px, 23px);
    }

    &:focus {
      background-color: transparent;
    }
  }

  &:hover .vjs-big-play-button {
    background-color: transparent;
  }

  &.vjs-paused .vjs-big-play-button {
    visibility: visible;
  }
}
