
//
// Lightbox.
//

.lightbox-popup {
  .mfp-content {
    width: 100%;
  }

  .popup-head {
    position: relative;

    width: 100%;
  }

  .popup-head-image {
    width: 100%;
    height: auto;
  }

  .mfp-close {
    right: 0;

    color: #fff;

    background-color: $orange;

    transition: opacity $fast-transition;
  }
}

.popup-head-text-container {
  @include position(absolute);

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: $unit-2 $unit-4;
}

// Lightbox move from top effect.
.mfp-move-from-top {
  .mfp-content {
    vertical-align: top;
  }

  .mfp-with-anim {
    opacity: 0;
    transform: translateY(-100px);
    transition: all .2s;
  }

  &.mfp-bg {
    background: $brown-darken;

    opacity: 0;
    transition: all .2s;
  }

  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: translateY(0);
    }

    &.mfp-bg {
      opacity: .9;
    }
  }

  &.mfp-removing {
    .mfp-with-anim {
      opacity: 0;
      transform: translateY(-50px);
    }

    &.mfp-bg {
      opacity: 0;
    }
  }
}

// Lightbox fade effect.
.mfp-fade {
  &.mfp-bg {
    opacity: 0;
    transition: all .15s ease-out;

    &.mfp-ready {
      opacity: .8;
    }

    &.mfp-removing {
      opacity: 0;
    }
  }

  &.mfp-wrap {
    .mfp-content {
      opacity: 0;
      transition: all .15s ease-out;
    }

    &.mfp-ready .mfp-content {
      opacity: 1;
    }

    &.mfp-removing .mfp-content {
      opacity: 0;
    }
  }
}

.mfp-fade-in .mfp-content {
  animation: fade-in .75s;

  animation-fill-mode: both;
  backface-visibility: hidden;
}

// Lightbox zoom effect.
.mfp-with-zoom {
  &.mfp-bg,
  .mfp-container {
    opacity: 0;
    transition: all .3s ease-out;
  }

  &.mfp-ready {
    .mfp-container {
      opacity: 1;
    }

    &.mfp-bg {
      opacity: .8;
    }
  }

  .mfp-removing {
    &.mfp-bg,
    .mfp-container {
      opacity: 0;
    }
  }
}

//
// Media queries.
//

// // Extra small screens.
@media (min-width: $screen-sm-min) {
  .popup-head-text-container {
    padding: $unit-8 $unit-4;
  }
}

// // Small screens.
@media (min-width: $screen-sm-min) {
  .mfp-close {
    right: -44px;
  }

  .lightbox-popup {
    .mfp-content {
      width: $modal-media-width;
    }

    .popup-head {
      height: 500px;
    }
  }

  .popup-head-text-container {
    padding: $unit-8;
  }
}
