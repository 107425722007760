
//
// Scaffolding.
//

* {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

html,
body {
  font-family: $font-family;
  font-size: $base-font-size;
  font-weight: 400;
  line-height: $base-line-height;
  letter-spacing: 1.176;
  color: $brown;
}

.no-overflow {
    overflow: hidden;
}

.pseudos {
  position: absolute;

  content: '';
}

.center-vertical {
  position: relative;
  z-index: 2;

  display: table;
  width: 100%;

  &-content {
    display: table-cell;

    vertical-align: middle;
  }
}

.center-align {
  position: absolute;
  top: 50%;
  left: 0;

  transform: translate(-50%, -50%);
}

.vertical-align {
  position: absolute;
  top: 50%;

  transform: translateY(-50%);
}

.horizontal-align {
  position: absolute;
  left: 50%;

  transform: translateX(-50%);
}

// Background color.
@include create-background-color('brown-dark', $brown-dark);
@include create-background-color('brown', $brown);
@include create-background-color('default', $brown-light);
@include create-background-color('gray', $gray);
@include create-background-color('light-muted', rgba(#fff, .5));
@include create-background-color('muted', rgba($gray, .5));
@include create-background-color('none', transparent);
@include create-background-color('orange-darken', $orange-darken);
@include create-background-color('orange-thin', $orange-thin);
@include create-background-color('orange', $orange);
@include create-background-color('primary', $brand-primary);
@include create-background-color('secondary', $brand-secondary);
@include create-background-color('white', #fff);

.bg-pattern-brown {
  background-repeat: no-repeat;
  background-size: cover !important;

  @include img-retina('../images/pattern-brown.jpg', '../images/pattern-brown@2x.jpg', auto, auto);
}

// Borders.
.border-gray-right {
  position: relative;

  &::before {
    @include position(absolute, 0, -1px, 0, 0, 1);

    content: '';
    border-right: 1px solid $gray;
  }
}

// Overlay.
.overlay-bg-orange-40::before {
  @extend .pseudos;
  @include create-overlay-background($orange, .4, 0);
}

.overlay-bg-orange-50::before {
  @extend .pseudos;
  @include create-overlay-background($orange, .5, 0);
}

.overlay-bg-orange-90::before {
  @extend .pseudos;
  @include create-overlay-background($orange, .9, 0);
}

//
// Media queries.
//

// Extra small screens.
@media(max-width: $screen-xs-max) {
  .col-xxs-12 {
    width: 100%;
  }

  .hidden-xxs {
    display: none;
    visibility: hidden;
  }
}
