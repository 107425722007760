
//
// Button groups.
//

.btn-group-btn-map {
  position: absolute;
  right: $unit-4;
  bottom: $unit-4;

  > .btn {
    width: $marker-control-width;
    height: $marker-control-height;
    min-width: $marker-control-width;

    border: 0;

    &.btn-bg-orange {
      border-bottom: 1px solid $brand-secondary;
    }

    + .btn {
      border-top: 1px solid $brand-secondary;
      border-bottom: 0;
    }
  }
}
