
//
// Images.
//

// Default background image styles.
.img {
  background-position: center center;
  background-repeat: no-repeat;
}

// Image cover.
.img-cover {
  background-size: cover;

  @extend .img;
}
