
//
// Map.
//

.map {
  position: absolute;
  top: 0;
  left: 0;

  @include square(100%);

  &.map-contacts {
    position: relative;

    width: 100%;
    height: 480px;
  }
}

//
// Media queries.
//

// Small screens.
@media(min-width: $screen-sm-min) {
  .map.map-contacts {
    position: fixed !important;
    right: 0;
    left: 50%;

    width: 50%;
    height: 100%;
  }
}
