
//
// Items.
//

.points-of-interest-list-item-link {
  position: relative;

  display: block;
  height: $item-poi-size;

  transition: $default-transition;

  &.odd {
    background-color: rgba($brown-dark, .5);
  }

  &:hover {
    background-color: $orange;

    .points-of-interest-list-item-link-image::before {
      opacity: 0;
    }
  }
}

.points-of-interest-list-item-link-title {
  max-height: $font-size-h3;
  padding-right: $list-gutter-width + $item-poi-size;
  padding-left: $list-gutter-width;
  overflow: hidden;

  @extend .vertical-align;
}

.points-of-interest-list-item-link-image {
  @extend .img-cover;
  @include square($item-poi-size);

  position: absolute;
  top: 0;
  right: 0;

  &::before {
    transition: $default-transition;

    @extend .pseudos;
    @include create-overlay-background($brown-dark, .5);
  }
}

// Item Media.
.item-media {
  position: relative;

  display: block;

  .item-media-container {
    padding: $list-gutter-width;
  }

  &-image {
    top: 0;
    left: 0;

    @include square(100%);
  }

  &-type {
    position: relative;

    display: block;
  }

  &-title {
    position: relative;

    height: 130px;
    overflow: hidden;

    line-height: 32px;
  }

  &-date,
  &-download {
    position: absolute;
    right: $list-gutter-width;
    bottom: $list-gutter-width;
    left: $list-gutter-width;

    white-space: nowrap;
  }

  &-download-icon {
    display: inline-block;
    margin-right: $base-unit;

    line-height: 35px;
    text-align: center;

    border: 2px solid #fff;
    border-radius: 50%;

    @include square(40px);

    svg {
      width: 14px;
      height: 15px;
    }
  }

  &:hover {
    text-decoration: none;
  }
}
