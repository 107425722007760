
//
// Buttons mixins.
//

// Create button.
@mixin create-button($name, $bg, $color, $border: null, $bg-hover: null, $color-hover: null, $border-hover: null) {
  .btn-#{$name} {
    color: $color;

    background-color: $bg;
    border-color: $border or $bg;
    border-width: 2px;

    &:active,
    &:focus,
    &:hover {
      color: $color-hover or $color;

      background-color: $bg-hover or darken($bg, 5%);
      border-color: $border-hover or darken($bg, 5%);
      text-decoration: none;
    }
  }
}

// Create button bordered.
@mixin create-button-bordered($name, $bg, $color, $color-icon, $border: null, $bg-hover: null, $color-hover: null, $color-icon-hover: null, $border-hover: null) {
  .btn-#{$name}-bordered {
    .btn-content-icon {
      color: $color-icon;
      background-color: $bg;
      border-color: $border or $color;
      border-width: 2px;
    }

    .btn-content-text {
      color: $color;
    }

    &:active,
    &:focus,
    &:hover {
      .btn-content-icon {
        background-color: $bg-hover or darken($color, 5%);
        border-color: $border-hover or darken($bg-hover or $color, 5%);
        color: $color-icon-hover or darken($color, 5%);
      }

      .btn-content-text {
        color: $color-hover or darken($color, 5%);
      }
    }
  }
}

// Button states.
@mixin button-states {
  &,
  &:active,
  &:focus,
  &:hover,
  &:visited {
    @content;
  }
}
