
//
// Type.
//

// Style headings.
@mixin create-default-heading-style {
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @content;
  }
}

// Create font properties
@mixin create-font-properties($size, $height, $space: 0) {
  font-size: $size;
  line-height: $height;
  letter-spacing: $space
}

// Create text colors.
@mixin create-text-color($name: null, $color: null) {
  .text-#{$name} {
    color: $color;
  }
}

// Create text transforms.
@mixin create-text-transform {
  @each $transform in (uppercase, lowercase, capitalize) {
    .text-#{$transform} {
      text-transform: $transform;
    }
  }
}

// Create font weight.
@mixin create-font-weight($name, $weight) {
  .text-#{$name} {
    font-weight: $weight;
  }
}

// Create text align.
@mixin create-text-align-all {
  @each $align in (center, left, right, justify) {
    .text-#{$align} {
      text-align: $align;
    }
  }
}

// Create text align responsive.
@mixin create-text-align($screen) {
  @each $align in (center, left, right, justify) {
    .text-#{$align}-#{$screen} {
      text-align: $align;
    }
  }
}
