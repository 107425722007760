
//
// Variables.
//

// Units.
$base-unit: 8px;
$unit-2: $base-unit * 2;
$unit-3: $base-unit * 3;
$unit-4: $base-unit * 4;
$unit-5: $base-unit * 5;
$unit-6: $base-unit * 6;
$unit-7: $base-unit * 7;
$unit-8: $base-unit * 8;
$unit-9: $base-unit * 9;
$unit-10: $base-unit * 10;
$unit-11: $base-unit * 11;
$unit-12: $base-unit * 12;

// Grid.
$grid-gutter-width: 6px;
$list-gutter-width: 30px;

// Breakpoints.
$screen-xs: 480px;
$screen-xs-max: $screen-xs - 1px;

// Columns.
$col-width: 1 / 12 * 100vw;
$col-height: 1 / 12 * 100vh;

// Font families.
$font-family: 'Ideal Sans SSm A', 'Ideal Sans SSm B', serif;

// Font sizes
$base-font-size: 16px;
$base-line-height: 1.872;

$font-size-h1: 131px;
$font-size-h2: 70px;
$font-size-h3: 47px;
$font-size-h4: 28px;
$font-size-h5: 12px;

// Colors.
$brand-primary: #d99d80;
$brand-secondary: #ffdbca;

$brown-dark: #312925;
$brown-darken: #523b31;
$brown-light: #f9f5f3;
$brown-thin: #decfbe;
$brown: #74665f;
$gray: #d4cdcb;
$orange-dark: #c4673c;
$orange-darken: #b76038;
$orange-light: #fbf1ec;
$orange-thin: #fb824a;
$orange: #d26c3d;

// Easing functions.
$ease: cubic-bezier(.4, 0, .1, 1);
$ease-bounce-out: cubic-bezier(.4, 0, 1, .32);
$ease-out-quart: cubic-bezier(.86, 0, .07, 1);
$ease-media: cubic-bezier(.22, .61, .36, 1);

// Transitions.
$default-transition-duration: .5s;
$default-transition: $default-transition-duration $ease;
$fast-transition-duration: .25s;
$fast-transition: $fast-transition-duration $ease;

$media-transition-duration: .4s;
$media-transition: $media-transition-duration $ease-media;

// Box shadow.
$box-shadow-small: 0 1px 2px 0 rgba(#000, .2);

// Buttons.
$button-width: 200px;
$button-xs-width: 150px;
$button-md-height: 60px;
$button-md-radius: $button-md-height / 2;
$button-sm-height: 45px;
$button-sm-radius: $button-sm-height / 2;

$button-rounded-size-xs: 32px;
$button-rounded-size-md: 45px;
$button-rounded-size-lg: 60px;

// Icon units.
$icon-unit: 4px;

// Logo size.
$logo-height-xs: 38px;
$logo-height: 45px;
$logo-width-xs: 99px;
$logo-width: 116px;

// Navicon.
$navicon-size: 46px;

// Media object.
$media-object-mascot-height: 205px;
$media-object-mascot-width: 185px;
$media-object-size-sm: 185px;
$media-object-size: 232px;

// Items
$item-horizontal-sm-height: 100px;
$item-media-height: 300px;
$item-poi-size: 100px;

// Markers.
$marker-size: 100px;
$marker-control-width: 75px;
$marker-control-height: 50px;

// Nav.
$nav-points-of-interest-width: 350px;
$menu-media-height: 60px;

// Menu.
$menu-width: 350px;

// Modal.
$modal-media-width: 750px;

// Species.
$species-list: (
  '1': 'golden-eagle',
  '2': 'boneli-eagle',
  '3': 'black-kite',
  '4': 'owl',
  '5': 'vulture',
  '6': 'griffon-vulture',
  '7': 'peregrine-falcon',
  '8': 'kestrel',
  '9': 'heron',
  '10': 'black-stork',
  '11': 'otter',
  '12': 'mole'
);

$heads: (
  black-kite: (
    height: 232px,
    width: 263px
  ),
  black-stork: (
    height: 232px,
    width: 303px
  ),
  boneli-eagle: (
    height: 232px,
    width: 247px
  ),
  golden-eagle: (
    height: 232px,
    width: 251px
  ),
  griffon-vulture: (
    height: 232px,
    width: 254px
  ),
  heron: (
    height: 232px,
    width: 371px
  ),
  kestrel: (
    height: 232px,
    width: 262px
  ),
  mole: (
    height: 247px,
    width: 319px
  ),
  otter: (
    height: 232px,
    width: 258px
  ),
  owl: (
    height: 237px,
    width: 269px
  ),
  peregrine-falcon: (
    height: 232px,
    width: 237px
  ),
  vulture: (
    height: 251px,
    width: 272px
  )
);
