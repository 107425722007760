
//
// Positions.
//

.position-xs-absolute {
  position: absolute;
}

.position-xs-relative {
  position: relative;
}

.position-top {
  top: 0;
}

.position-right {
  right: 0;
}

.position-bottom {
  bottom: 0;
}

.position-left {
  left: 0;
}

// Small screens.
@media (min-width: $screen-sm-min) {
  .position-sm-absolute {
    position: absolute;
  }

  .position-sm-relative {
    position: relative;
  }
}

// Medium screens.
@media (min-width: $screen-md-min) {
  .position-md-absolute {
    position: absolute;
  }

  .position-md-relative {
    position: relative;
  }
}

// Large screens.
@media (min-width: $screen-lg-min) {
  .position-md-absolute {
    position: absolute;
  }

  .position-md-relative {
    position: relative;
  }
}
