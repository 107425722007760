
//
// Type.
//

// General styles applied to all headers.
@include create-default-heading-style {
  margin: 0;

  font-family: $font-family;
  font-weight: 400;
  line-height: $base-line-height;
  letter-spacing: 0;
}

.h1,
h1 {
  @include create-font-properties($font-size-h2, 69px, -1.68px);
}

.h2,
h2 {
  @include create-font-properties($font-size-h3, 56px, -1px);
}

.h3,
h3 {
  @include create-font-properties($font-size-h4, 41px, -.3px);
}

.h4,
h4 {
  @include create-font-properties($base-font-size, $base-line-height, 0);
}

.h5,
h5 {
  @include create-font-properties($font-size-h5, 14px);
}

.p,
p {
  margin: 0;

  @include create-font-properties($base-font-size, $base-line-height, -.8px);
}

.text-small {
  line-height: 22px;
  letter-spacing: 0;
}

// Text color.
@include create-text-color('black-muted', rgba(#000, .5));
@include create-text-color('brown-dark', $brown-dark);
@include create-text-color('brown-light', $brown-light);
@include create-text-color('brown', $brown);
@include create-text-color('gray', $gray);
@include create-text-color('light-muted', rgba(#fff, .5));
@include create-text-color('muted', rgba($gray, .5));
@include create-text-color('orange', $orange);
@include create-text-color('primary', $brand-primary);
@include create-text-color('secondary', $brand-secondary);
@include create-text-color('transparent', transparent);
@include create-text-color('white', #fff);

// Font weights.
@include create-font-weight('bold', 700);
@include create-font-weight('normal', 400);
@include create-font-weight('light', 300);
@include create-font-weight('thin', 100);

// Text transform.
@include create-text-transform;

//
// Text alignment.
//

// Extra small screens.
@include create-text-align-all;
@include create-text-align(xs);

// Small screens.
@media (min-width: $screen-sm-min) {
  @include create-text-align(sm);
}

// Medium screens.
@media (min-width: $screen-md-min) {
  @include create-text-align(md);
}

// Large screens.
@media (min-width: $screen-lg-min) {
  @include create-text-align(lg);
}

//
// Media queries.
//

// Extra small screens.
@media (max-width: $screen-xs-max) {
  .h1,
  .h2,
  h1,
  h2 {
    @include create-font-properties(35px, 45px, -1px);
  }
}

// Small screens.
@media(min-width: $screen-sm-min) {
  .h1,
  h1 {
    @include create-font-properties($font-size-h1, 120px, -1.66px);
  }

  .h2,
  h2 {
    @include create-font-properties($font-size-h2, 80px, -1.68px);
  }

  .h3,
  h3 {
    @include create-font-properties($font-size-h3, 56px, -1px);
  }

  .h4,
  h4 {
    @include create-font-properties($font-size-h4, 41px, -.3px);
  }
}
