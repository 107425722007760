
//
// Markers.
//

.marker {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;

  text-align: center;

  background-color: $orange;
  border: 0;
  border-radius: 50%;

  opacity: 0;
  transform: scale(.2);
  box-shadow: $box-shadow-small;
  transition: $fast-transition;

  @include square($marker-size);
  align-items: center;

  &.active,
  &:hover {
    text-decoration: none;

    opacity: 1;
    transform: scale(1);

    .marker-title {
      opacity: 1;
    }
  }
}

.marker-title {
  width: 75%;
  margin: 0 auto !important;

  color: $brand-secondary;

  opacity: 0;
  transition: $fast-transition;
  transition-delay: .2s;
}
