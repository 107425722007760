
//
// Layout.
//

.layout,
.layout-row,
[layout] {
  display: inline-block;

  > * {
    display: inline-block;
  }

  &::before {
    content: '';
    display: inline-block;
    height: 100%;
  }
}

// Remove float property.
.no-float {
  float: none;
}

//
// Z indexes.
//

@for $i from 0 through 10 {
  [layout-index="#{$i}"] {
    z-index: $i;
  }
}

// Overflow.
.no-overflow,
[layout-overflow] {
  overflow: hidden;
}

//
// Positions.
//

@each $position in (absolute, fixed, relative, static) {
  @include make-layout-position($position);
}

//
// Layout alignment.
//

// Horizontal.
.layout-fill,
[layout-fill="horizontal"] {
  width: 100%;
}

.layout-absolute-fill {
  position: absolute;
  top: 0;
  left: 0;

  @include square(100%);
}

// Extra small screens.
@media (max-width: $screen-xs-max) {
  @include make-layout-fill-horizontal(xs);
}

// Small screens.
@media (max-width: $screen-sm-max) {
  @include make-layout-fill-horizontal(sm);
}

// Medium screens.
@media (max-width: $screen-md-max) {
  @include make-layout-fill-horizontal(md);
}

// Large screens.
@media (max-width: $screen-lg) {
  @include make-layout-fill-horizontal(lg);
}

// Vertical.
[layout-fill="vertical"] {
  min-height: 100vh;
}

// Extra small screens.
@media (max-width: $screen-xs-max) {
  @include make-layout-fill-vertical(xs);
}

// Small screens.
@media (max-width: $screen-sm-max) {
  @include make-layout-fill-vertical(sm);
}

// Medium screens.
@media (max-width: $screen-md-max) {
  @include make-layout-fill-vertical(md);
}

// Large screens.
@media (max-width: $screen-lg) {
  @include make-layout-fill-vertical(lg);
}

//
// Layout alignment.
//

// Extra small screens.
@include make-layout-align(xs);
@include make-column-padding(xs);
@include make-column-height(xs);
@include make-margin-for-all-direction(xs);
@include make-margin-for-horizontal-direction(xs);
@include make-margin-for-vertical-direction(xs);
@include make-padding-for-all-direction(xs);
@include make-padding-for-horizontal-direction(xs);
@include make-padding-for-vertical-direction(xs);

// Small screens.
@media (min-width: $screen-sm-min) {
  @include make-layout-align(sm);
  @include make-column-padding(sm);
  @include make-column-height(sm);
  @include make-margin-for-all-direction(sm);
  @include make-margin-for-horizontal-direction(sm);
  @include make-margin-for-vertical-direction(sm);
  @include make-padding-for-all-direction(sm);
  @include make-padding-for-horizontal-direction(sm);
  @include make-padding-for-vertical-direction(sm);
}

// Medium screens.
@media (min-width: $screen-md-min) {
  @include make-layout-align(md);
  @include make-column-padding(md);
  @include make-column-height(md);
  @include make-margin-for-all-direction(md);
  @include make-margin-for-horizontal-direction(md);
  @include make-margin-for-vertical-direction(md);
  @include make-padding-for-all-direction(md);
  @include make-padding-for-horizontal-direction(md);
  @include make-padding-for-vertical-direction(md);
}

// Large screens.
@media (min-width: $screen-lg-min) {
  @include make-layout-align(lg);
  @include make-column-padding(lg);
  @include make-column-height(lg);
  @include make-margin-for-all-direction(lg);
  @include make-margin-for-horizontal-direction(lg);
  @include make-margin-for-vertical-direction(lg);
  @include make-padding-for-all-direction(lg);
  @include make-padding-for-horizontal-direction(lg);
  @include make-padding-for-vertical-direction(lg);
}

@each $direction in (top, right, bottom, left) {
  // Extra small screens.
  @include make-margin-for-direction(xs, $direction);
  @include make-padding-for-direction(xs, $direction);
  @include make-pull-for-direction(xs, $direction);

  // Small Screens.
  @media (min-width: $screen-sm-min) {
    @include make-margin-for-direction(sm, $direction);
    @include make-padding-for-direction(sm, $direction);
    @include make-pull-for-direction(sm, $direction);
  }

  // Medium Screens.
  @media (min-width: $screen-md-min) {
    @include make-margin-for-direction(md, $direction);
    @include make-padding-for-direction(md, $direction);
    @include make-pull-for-direction(md, $direction);
  }

  // Large Screens.
  @media (min-width: $screen-lg-min) {
    @include make-margin-for-direction(lg, $direction);
    @include make-padding-for-direction(lg, $direction);
    @include make-pull-for-direction(lg, $direction);
  }
}

//
// Layout index.
//

[layout-index="1"] {
  z-index: 1;
}

//
// Layout center element.
//

.layout-absolute-center,
[layout-absolute-center] {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

//
// Layout clear.
//

// Extra small screens.
[layout-block-xs] {
  display: block !important;
}

[layout-no-block-xs] {
  display: inline-block !important;
}

// Small screens.
@media (min-width: $screen-sm-min) {
  [layout-block-sm] {
    display: block !important;
  }

  [layout-no-block-sm] {
    display: inline-block !important;
  }
}

// Medium screens.
@media (min-width: $screen-md-min) {
  [layout-block-md] {
    display: block !important;
  }

  [layout-no-block-md] {
    display: inline-block !important;
  }
}

// Large screens.
@media (min-width: $screen-lg-min) {
  [layout-block-lg] {
    display: block !important;
  }

  [layout-no-block-lg] {
    display: inline-block !important;
  }
}
