
//
// Navbar.
//

.navicon {
  @include position(fixed, $unit-4, auto, auto, $unit-4, $zindex-navbar-fixed);
  @include square($navicon-size);

  margin: -1px;

  border: 2px solid $brand-primary;
  border-radius: $navicon-size / 2;

  transition: background-color $default-transition, border $default-transition;

  &:hover {
    border-color: $orange;
    background-color: $orange;

    .navicon-lines {
      &,
      &::before,
      &::after {
        background-color: #fff;
      }
    }
  }

  &.is-preloading {
    border-color: $brand-secondary;

    &::after {
      @include position(absolute, -2px, -2px, -2px, -2px);
      @include square($navicon-size);
      @include bg-retina('../images/preloader', 'png', $navicon-size, $navicon-size);

      border: 2px solid transparent;

      box-sizing: border-box;
      border-radius: 50%;
      content: '';

      animation: rotation 1.5s linear infinite;
    }

    .navicon-lines {
      opacity: 0;

      transform: translate(-50%, -50%) scale(0);
    }
  }
}

.navicon-lines {
  @include position(absolute, 50%, auto, auto, 50%);

  width: $unit-3;

  transform: translate(-50%, -50%);

  &,
  &::before,
  &::after {
    height: 2px;

    background-color: $orange;

    transition: $default-transition;
    transition-property: background-color, transform, width;
  }

  &::before,
  &::after {
    @include position(absolute, auto, 0, auto, 0);

    width: $unit-2;
    margin: 0 auto;

    content: '';

    transform-origin: 50% 50%;
  }

  &::before {
    transform: translateY(-6px);
  }

  &::after {
    transform: translateY(6px);
  }
}

.navbar-logo {
  @include position(fixed, $unit-4, $unit-4, auto, auto, $zindex-navbar-fixed);

  color: $orange;
  transition: color $default-transition .25s;

  .icon {
    width: $logo-width-xs;
    height:$logo-height-xs;
    margin-top: 3px;
  }
}

.menu-open {
  .navicon-lines {
    border-color: $brand-secondary;
    background-color: transparent !important;

    &::before,
    &::after {
      width: 20px;

      background-color: $brand-secondary;
    }

    &::before {
      transform: translateY(0) rotate3d(0, 0 , 1, 45deg);
    }

    &::after {
      transform: translateY(0) rotate3d(0, 0 , 1, -45deg);
    }
  }

  .navbar-logo {
    color: $brand-secondary;

    transition-delay: 0s;
  }
}

.nav-media {
  position: relative;

  width: 100%;
  margin: 0 auto;
}

//
// Media queries.
//

// Extra small screens.
@media (min-width: $screen-xs-min) {
  .navbar-logo .icon {
    width: $logo-width;
    height: $logo-height;
    margin-top: 0;
  }
}

// Small screens.
@media(min-width: $screen-sm-min) {
  .nav-media {
    margin-top: -$menu-media-height;
  }
}
